import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserModel } from '../../models/user/user.model';
import { DomSanitizer } from '@angular/platform-browser';
import { EditUserModalComponent } from '../../dialogs/edit-user-dialog/edit-user-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneralApiService } from '../../services/general-api.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service'
import { AppConfig } from 'src/app/app.config';

@Component({
  selector: 'main-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {
  public user: UserModel
  public studentId: any
  public organizationName: any
  public userData: UserModel
  public userRole: string;
  public redirect:any = AppConfig.settings.hosts.web;
  constructor(
    private _authService: AuthService,
    private _sanitizer: DomSanitizer,
    private matDialog: MatDialog,
    private _apiService: GeneralApiService,
    private _activeRoute: ActivatedRoute,
    ) {
    this.user = new UserModel()
    this.userData = new UserModel()
  }

  ngOnInit(): void {
    this._activeRoute.firstChild.params.subscribe(param => {
      if(param.studentId) {
        this.studentId = param.studentId
      }
    })
    let localUser = JSON.parse(localStorage.getItem('user'));
    //console.log(localUser, 'localUser');
    
    if(localUser) {
      this.userData = localUser.user;
      this.userRole = localUser.role;
    } else {
      this.userData = null
    }
        
    this.getDate();
    // let storageInfo = localStorage.getItem('userInfo')
    let storedCookie = document.cookie.replace(/(?:(?:^|.*;\s*)userInfo\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    this.organizationName = localStorage.getItem('organizationName');   
    if(storedCookie.includes('id')) {
      let userInfo = JSON.parse(storedCookie)
      if(this.userData) {
        this.getUser(this.userData.id)
      }
    }
    else{
      //this.logout()
    }
    
  }

  private getUser(id: any) {
    this._apiService.getUser(id).subscribe((res: any) => {
      if(res) {       
        if(res.length > 0) {
          this.user = res
        }
      }
    }, error => {
      console.log(error)
    })
  }

  public setHelpOn(){

    localStorage.setItem('onLoadHelp', 'true');
    localStorage.setItem('runTeacherStudents', '');
    localStorage.setItem('runTeachers', '');
    localStorage.setItem('runAdmins', '');
    localStorage.setItem('runMentors', '');
    localStorage.setItem('onLoadCustomerHelp', 'true');
    localStorage.setItem('onLoadOrganizationHelp', 'true');
    localStorage.setItem('onLoadStudentsHelp', 'true');
    window.location.reload();
  }

  public editUser() {
    this.matDialog.open(EditUserModalComponent,  { 
      height: 'auto',
      width: '608px',
      disableClose: true,
      data: this.userData
    }).beforeClosed().subscribe((res:UserModel) => {
      if(res) {
        console.log(res,'respuesta del edit');
        let localUser = JSON.parse(localStorage.getItem('user'));
        this.userData.first_name = res.first_name
        this.userData.last_name = res.last_name
        this.userData.img = res.img
        this.userData.position = res.position
        localUser.user = this.userData
        localStorage.setItem('user', JSON.stringify(localUser));

        this.user = res
      }
    })
  }
  public getDate(){
    this._apiService.pageDate().subscribe(data => {
      localStorage.setItem('date', data.date);
      return data.date
    })
  }

  public logout() {
    this._authService.logout();
  }

  public goBack() {
    window.history.back();
  }

}
